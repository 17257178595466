import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


  // @TODO
  // (DONE!!!) 1.Button Animation
  // (DONE!!!) 2.Loading Page
  // (DONE!!!) 3.Input Field
  // (DONE!!!) 4.FAQ (API, which markets support, intrinsic value calculation, why analysts important, US market only)
  // (DONE!!!) 5.Data Updated on xxx
  // 6.Share function
  // (DONE!!!) 7.Menu items for mobile screen
  // (DONE!!!)8.Hover over message for nav icons
  // (DONE!!!) 9.MacBook screen size
  // (DONE!!!) 10. Footer online green dot spinnin
  // (DONE!!!) 11. Firebabse Realtime Database!


  // @FUTURE FEATURES
  // 1. Check ticker inputs match
  // 2. Login / Sign Up
  // 3. Subscription tier


  // */}

