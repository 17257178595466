import { initializeApp } from 'firebase/app'
//import { getFirestore } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyBlPgIivzDr0mo3ESsfPGF0lc6XXna7DuE",
    authDomain: "wallstreet-analysts-bot.firebaseapp.com",
    databaseURL: "https://wallstreet-analysts-bot-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "wallstreet-analysts-bot",
    storageBucket: "wallstreet-analysts-bot.appspot.com",
    messagingSenderId: "265158226641",
    appId: "1:265158226641:web:b1613b647acc51ff04cbf0",
    measurementId: "G-XMT4G23ECB"
};

  //init firebase
  const app = initializeApp(firebaseConfig)

  //get database
  const db = getDatabase(app)

  export { db }