import './App.css';
import Mainpage from './components/Mainpage';
//import Testing from './components/Testing';

function App() {

  return (
    <div className="App">

      <Mainpage />

    </div>
    
  );
}

export default App;
